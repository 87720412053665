<template>
  <div class="apipe-cloud-course-page">
    <top-nav-new />
    <div>
      <img src="@/assets/newSite/news/det.png" alt="" style="width: 100%;" />
    </div>
    <div class="pc-detail-cot container d-none d-lg-block">
      <div class="nav">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/case' }"
            >案例列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>案例详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <h4>{{ details.title }}</h4>
        <div class="times">
          <span>{{ details.submitTime }}</span>
          <!-- <img class="like" src="@/assets/newSite/news/like.png" alt="" />
          <span class="nums">{{ details.num }}</span> -->
          <!-- <img class="eyes" src="@/assets/newSite/news/eyes.png" alt="" />
          <span class="look">{{ details.viewNum }}</span> -->
        </div>
        <p class="domHTML" v-html="details.context"></p>
        <div class="opt">
          <div>
            <span
              :class="[
                'el',
                'el-icon-arrow-left',
                details.upContentId ? 'act' : 'dis',
              ]"
              @click="handleUp(details.upContentId)"
              >上一篇</span
            >
            <span
              style="margin-left: 15px"
              :class="[details.downContentId ? 'act' : 'dis']"
              @click="handleDown(details.downContentId)"
              >下一篇</span
            >
            <span
              :class="[
                'el',
                'el-icon-arrow-right',
                details.downContentId ? 'act' : 'dis',
              ]"
            ></span>
          </div>
          <div v-if="false">
            <img
              v-if="isLikes"
              :src="img2"
              alt=""
              style="cursor: not-allowed"
            />
            <img
              v-else
              @click="hanldLike"
              :src="img1"
              alt=""
              style="cursor: pointer"
            />
          </div>
          <div>
            <span>分享：</span>
            <img
              @click="handleShare"
              style="cursor: pointer"
              src="@/assets/newSite/detail/wechat.png"
              alt=""
            />
            <img
              @click="handleShare"
              style="margin: 0 10px; cursor: pointer"
              src="@/assets/newSite/detail/weibo.png"
              alt=""
            />
            <img
              @click="handleShare"
              style="cursor: pointer"
              src="@/assets/newSite/detail/quan.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-detail-cot container d-block d-lg-none">
      <div class="content">
        <div class="back" @click="handleBack">
          <span class="el el-icon-arrow-left">返回</span>
        </div>
        <h4>{{ details.title }}</h4>
        <div class="times">
          <span>{{ details.submitTime }}</span>
          <!-- <img class="like" src="@/assets/newSite/news/like.png" alt="" />
          <span class="nums">{{ details.num }}</span> -->
          <!-- <img class="eyes" src="@/assets/newSite/news/eyes.png" alt="" />
          <span class="look">{{ details.viewNum }}</span> -->
        </div>
        <p class="domHTML" v-html="details.context"></p>
        <div class="opt">
          <div style="margin-bottom: 10px" v-if="false">
            <!-- <img class="zan" src="@/assets/newSite/detail/zan.png" alt="" /> -->
            <img
              class="zan"
              v-if="isLikes"
              :src="img2"
              alt=""
              style="cursor: not-allowed"
            />
            <img
              class="zan"
              v-else
              @click="hanldLike"
              :src="img1"
              alt=""
              style="cursor: pointer"
            />
          </div>
          <div>
            <span>分享：</span>
            <img
              class="lnk"
              @click="handleShare"
              src="@/assets/newSite/detail/wechat.png"
              alt=""
            />
            <img
              @click="handleShare"
              class="lnk"
              style="margin: 0 10px"
              src="@/assets/newSite/detail/weibo.png"
              alt=""
            />
            <img
              class="lnk"
              @click="handleShare"
              src="@/assets/newSite/detail/quan.png"
              alt=""
            />
          </div>
        </div>
        <div class="next-pre">
          <div>
            <span
              :class="[
                'el',
                'el-icon-arrow-left',
                details.upContentId ? 'act' : 'dis',
              ]"
              @click="handleUp(details.upContentId)"
              >上一篇</span
            >
          </div>
          <div>
            <span
              :class="[details.downContentId ? 'act' : 'dis']"
              @click="handleDown(details.downContentId)"
              >下一篇</span
            >
            <span
              :class="[
                'el',
                'el-icon-arrow-right',
                details.downContentId ? 'act' : 'dis',
              ]"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <NewFooter />
  </div>
</template>
            
<script>
import { Newsdetail, isLike, clickzan } from "@/api/fetch";
import Clipboard from "vue-clipboard3";
export default {
  name: "hardware",
  metaInfo() {
    return {
      title:this.tt,
      meta: [
        { name: 'keywords', content: '工业互联网新闻,优制云公司动态,SaaS行业动态,伟班平台' },
        { name: 'description', content: '优制云工业互联网平台,分享行业新鲜资讯，帮助您轻松了解行业动态和未来趋势' },
        
      ],
    };
  },
  data() {
    return {
      tt:'',
      details: "",
      isLikes: false,
      img1: require("@/assets/newSite/detail/zan.png"),
      img2: require("@/assets/newSite/detail/zanyet.png"),
      origin: "",
    };
  },
  async created() {
    const id = this.$route.query.id;
    this.tt = this.$route.query.tit;
    this.origin = this.$route.query.origin || "";
    await this.getDetails(id);
    await this.getStatus(id);
  },

  methods: {
    async handleShare(info) {
      const { toClipboard } = Clipboard();
      const link = window.location.href;
      try {
        await toClipboard(link);
        this.$message.success("复制链接成功");
      } catch (e) {
        this.$message.success("复制链接失败");
      }
    },

    async handleUp(id) {
      if (!id) return;
      const detailRes = await Newsdetail({ id }).catch(console.error);
      if (!detailRes) return;
      this.details = detailRes.data;
      this.$router.push({ query: { id, tit: detailRes.data.contentTitle } });
      document.documentElement.scrollTop = 0;
    },
    async handleDown(id) {
      if (!id) return;
      const detailRes = await Newsdetail({ id }).catch(console.error);
      if (!detailRes) return;
      this.details = detailRes.data;
      //修改当前路由参数
      this.$router.push({ query: { id, tit: detailRes.data.contentTitle } });
      document.documentElement.scrollTop = 0;
    },
    activeNextPre(id, tit) {
      this.$router.push({ query: { id: id, tit } });
    },
    async hanldLike() {
      if (!this.isLikes) {
        const obj = {
          contentId: this.details.id,
          likeStatus: 1,
        };
        const res = await clickzan(obj).catch((e) => {
          this.$message.error("操作失败");
        });
        if (!res) return;
        this.$message.success("点赞成功");
        // this.getDetails(this.details.id);
        this.getStatus(this.details.id);
      }
    },
    async getStatus(id) {
      const staus = await isLike({ contentId: id }).catch(console.error);
      this.isLikes = staus.data;
    },
    handleBack() {
      if (this.origin) {
        this.$router.push("/");
      } else {
        this.$router.push("/case");
      }
    },
    async getDetails(id) {
      let obj = {
        id: id,
      };
      const detailRes = await Newsdetail(obj).catch(console.error);
      if (!detailRes) return;
      this.details = detailRes.data;
    },
  },
};
</script>
            
  <style lang="less">
.domHTML {
  overflow: hidden;
  img {
    color: transparent !important;
    font-size: 0 !important;
    width: 100% !important;
  }
}

.mobile-detail-cot {
  .domHTML {
    img{
      width: 100% !important;
    }
  }
}
.apipe-cloud-course-page {
  background: #f5f6f7;
  .pc-detail-cot {
    padding: 30px 100px 100px 100px;
    .content {
      min-height: 300px;
      background: white;
      margin-top: 20px;
      padding: 20px;
      border-radius: 4px;
      text-align: left;
      .times {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 10px;
        .like,
        .eyes {
          width: 16px !important;
          height: 16px !important;
          margin: 0 10px 2px 16px;
        }
        .nums,
        .look {
          color: #999999;
        }
      }
      .opt {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eeeeee;
        padding: 15px 5px;
        img {
          width: 24px;
          height: 24px;
        }
        .dis {
          cursor: not-allowed;
        }
        .act {
          color: #1484ff;
          cursor: pointer;
        }
      }
    }
  }

  .mobile-detail-cot {
    // padding: 100px;
    .content {
      min-height: 300px;
      background: white;
      margin-top: 20px;
      padding: 10px;
      border-radius: 4px;
      text-align: left;
      .back {
        margin-bottom: 10px;
      }
      .times {
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 10px;
        .like,
        .eyes {
          width: 16px !important;
          height: 16px !important;
          margin: 0 10px 2px 16px;
        }
        .nums,
        .look {
          color: #999999;
        }
      }
      .opt {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #eeeeee;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        .zan {
          width: 36px;
          height: 36px;
        }
        .lnk {
          width: 24px;
          height: 24px;
        }
      }
      .next-pre {
        display: flex;
        justify-content: space-between;
        .dis {
          cursor: not-allowed;
        }
        .act {
          color: #1484ff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
            